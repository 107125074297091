<!-- 双色球选号 -->
<template>
    <div class="tem">
        <!-- 居左 号码选择 -->
        <div class="lef">
            <div class="lef-title">
                <strong>七乐彩选号</strong>
                <!-- <select style="width: 100px" ref="col">
              <option :value="item.val" v-for="(item, i) in option" :key="i">
                {{ item.name }}
              </option>
            </select> -->
            </div>
            <div class="number">
                <div class="ball" :class="{ active: arr.indexOf(i) == -1 }" v-for="(item, i) in 30" :key="i"
                    @click="ball(i)">
                    {{ 10 > item ? "0" + item : item }}
                </div>
                <div class="clear"></div>
            </div>
            <div style="width: 70%; margin: 0 auto">
                <button class="button" @click="ball3(i)" v-for="(item, i) in button" :key="i">
                    {{ item }}
                </button>
            </div>
            <div class="condition" style="margin-top: 10px">
                <div class="condition-1" @click="Rotationmatrix()">旋转矩阵</div>
                <div class="condition-2">
                    <ul>
                        <li @click="Courage()">胆码组</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                    </ul>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <!-- 居中 过滤条件-->
        <div class="con">
            <div class="con-title">过滤条件</div>
            <div class="con-1">
                <strong>条件显示区</strong>
                <ul>
                    <li class="con-list">
                        <div class="con-1a" v-for="(item, i) in condition" :key="i">
                            <div class="con-1aa">胆码组{{ item.dmz }}</div>
                            <div class="con-1ab">
                                <div class="con-1ab" @click="del(i, item)">删除</div>
                                <div class="con-1ab" @click="rev(i)">修改</div>
                            </div>
                            <div class="con-1ac">{{ item.dm }}出{{ item.gs }}个</div>
                            <div class="clear"></div>
                        </div>
                    </li>
                </ul>
                <ul v-show="ok">
                    <li class="con-list">
                        <div class="con-1a" v-for="(item, i) in matrix" :key="i">
                            <div class="con-1aa">旋转矩阵</div>
                            <div class="con-1ab">
                                <div class="con-1ab" @click="del2()">删除</div>
                            </div>
                            <div class="con-1ac">中 {{ matrix }} 保 {{ matrix - 1 }}</div>
                            <div class="clear"></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="con-2">
                <ul>
                    <!-- <li @click="test ()">
                <input type="file" ref="fileinput" v-show="false" name="fileContent">
              </li> -->
                    <input id="filePath" name="filePath" ref="fileinput" v-show="false" type="file" />
                    <!-- <input id="filePath" class="filepath" name="filePath" ref="fileinput"  type="file"><br> -->
                    <li id="btn" @click="doUpload">导入条件</li>
                    <li @click="save()">保存条件</li>
                    <li @click="reset()">重置条件</li>
                    <li class="zhixing" @click="filter()">执行过滤</li>
                </ul>

                <div class="clear"></div>
            </div>
        </div>
        <!-- 居右 过滤结果-->
        <div class="rig">
            <div class="rig-title">过滤结果</div>
            <div class="rig-1">
                <ssqlist ref="ssqlist" @money="mon"></ssqlist>
                <!-- @fData="ssqlist_1" -->
            </div>
            <div class="rig-2">
                <div class="rig-21">
                    <button @click="savetxt(sele)">保 存 号 码</button>
                </div>
                <div class="rig-22">
                    <button @click="doUpload2">导 入 号 码</button>
                </div>
                <!-- <div class="rig-23">
                    <button @click="windblue1">蓝 球 处 理</button>
                </div> -->
                <input id="filePath2" name="filePath2" ref="fileinput2" v-show="false" type="file" />
                <div class="clear"></div>
            </div>
            <div class="rig-3">
                <div class="rig-31">总计：{{ money }}注</div>
                <div class="rig-32">合计：{{ money * 2 }}元</div>
            </div>
        </div>
        <!-- <form action="" enctype="multipart/form-data">//暂未使用
          <input id="file" class="filepath" @click="changepic(this)" type="file"><br>
          <img src="" id="show" width="500">
        </form> -->
        <!-- <video width='500' height='300' id='video' controls></video>//暂未使用 -->
        <!-- <upload></upload> -->
        <div class="clear"></div>
        <!-- :inputname 为 props[]父组件向子组件传值,@closeDialog="close" 子组件给父组件关闭信号, closeDialog为子组件函数名称或标记（与父组件连接桥梁），close为父组件方法，false是传递的数据-->
        <wind :inputname="condition" @closeDialog="close" @func="getMsgFormSon" ref="wind" :dd="arr3" v-if="wind">
        </wind>
        <windmatrix :zi="matrix" @func="matrix1" @closeDialog="close" @close="close2" v-if="windmatrix"></windmatrix>
        <windblue @closeDialog="close" @close="close2" v-if="windblue"></windblue>
    </div>
</template>

<script>
    //import api2 from "@/js/FileSaver.js"
    // import { onDeactivated } from 'vue'
    import api from "@/js/mpublic.js"; //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    import wind from "@/components/Number-select/qlc-num/qlc-wind.vue";
    // import upload from "@/components/Number-select/klb-num/upload.vue";
    import windmatrix from "@/components/Number-select/ssq-num/ssq-wind-matrix.vue";
    import windblue from "@/components/Number-select/ssq-num/ssq-wind-blue.vue";
    import ssqlist from "@/components/Number-select/ssq-num/ssq-list.vue";
    import bus from '@/js/mitt'
    // import $ from 'jquery'
    export default {
        name: "",
        components: {
            ssqlist,
            windmatrix,
            wind,
            windblue,
        },
        data() {
            // 数据源
            return {
                arr: [], //下标/选
                arr0: [], //号码/选
                arr1: [], //投注方式
                sele: [], //号码数据
                arr2: [], //计算号码
                arr3: [], //当前条件修改项
                lan: [],//蓝球处理
                //wanfa:[],//当前玩法
                matrix: [], //矩阵条件显示
                condition: [],
                wind: false,
                windmatrix: false,
                //windblue: false,
                ok: false, //矩阵条件显示
                yi: [],
                er: [],
                img: [],
                how: [7],
                money: [],//显示list组件中的记录总金额
                button: [
                    "0尾",
                    "1尾",
                    "2尾",
                    "3尾",
                    "4尾",
                    "5尾",
                    "6尾",
                    "7尾",
                    "8尾",
                    "9尾",
                    "奇数",
                    "偶数",
                    "大尾",
                    "小尾",
                    "质数",
                    "合数",
                    "147尾",
                    "258尾",
                    "0369尾",
                    "全选",
                    "清除",
                ],
            };
        },
        // created() {//session监听
        //   window.addEventListener('setItem', () => {
        //     this.newVal = sessionStorage.getItem('watchStorage');
        //   })
        // },
        mounted() {
            bus.on('bus', data => {//处理蓝球循环
                var arr = []
                if (data.data != '') {
                    if (data.id == 0) {
                        var i = 0
                        for (var a = 0; a < this.sele.length; a++) {
                            arr.push('---' + data.data[i])
                            if (i < data.data.length - 1) {
                                i++
                            } else {
                                i = 0
                            }
                        }

                        this.$refs.ssqlist.parentHandleclick(this.sele, arr);

                    } else if (data.id == 1) {
                        var arr0 = []
                        for (var b = 0; b < data.data.length; b++) {
                            for (var c = 0; c < this.sele.length; c++) {
                                arr0.push(this.sele[c])
                                arr.push('---' + data.data[b])
                            }
                        }
                        this.$refs.ssqlist.parentHandleclick(arr0, arr);

                    } else if (data.id == 2) {
                        var a1 = data.data.length
                        var a2 = this.sele.length
                        var a3 = parseInt(a2 / a1);

                        if (a2 <= a1) {
                            var i1 = 0
                            for (var ax = 0; ax < this.sele.length; ax++) {
                                arr.push('---' + data.data[i1])
                                if (i1 < data.data.length - 1) {
                                    i1++
                                } else {
                                    i1 = 0
                                }
                            }
                            this.$refs.ssqlist.parentHandleclick(this.sele, arr);

                        } else {
                            var j = 0;
                            var k = 0;
                            for (var d = 0; d < this.sele.length; d++) {

                                if (j < a3) {
                                    j++
                                } else {
                                    j = 1
                                    if (k < data.data.length - 1) {
                                        k++
                                    } else {
                                        k = 0
                                    }

                                }
                                arr.push('---' + data.data[k])
                            }
                            this.$refs.ssqlist.parentHandleclick(this.sele, arr);

                        }



                    }

                }
                // this.parentHandleclick(this.arr0)
            })

        },
        computed: {
            // sd() {
            //     return this.$store.state.sd;
            // },
        },
        // 函数体部分，js的主要逻辑控制
        methods: {
            //上传图片及视频/暂未使用
            getObjectURL(file) {
                console.log(file);
                var url = null;
                // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
                if (window.createObjectURL != undefined) {
                    // basic
                    url = window.createObjectURL(file);
                } else if (window.URL != undefined) {
                    // mozilla(firefox)
                    url = window.URL.createObjectURL(file);
                } else if (window.webkitURL != undefined) {
                    // webkit or chrome
                    url = window.webkitURL.createObjectURL(file);
                }
                return url;
            },

            // 调用上传图片及视频/暂未使用
            changepic() {
                //console.log(obj.files[0]);//这里可以获取上传文件的name
                // 转换格式
                var files = this.$refs.fileinput.files;
                var newsrc = this.getObjectURL(files[0]);
                console.log(newsrc);
                // 赋值
                // 视频
                // document.getElementById('video').src = newsrc;
                // 图片
                document.getElementById("show").src = newsrc;
            },

            //关闭弹窗
            close() {
                this.wind = false;
                this.windmatrix = false;
                this.windblue = false;
            },

            close2() {
                this.matrix = [];
                this.windmatrix = false;
                this.windblue = false;
                this.ok = false;
            },
            //号码点击选择函数
            ball(i) {
                if (this.arr.indexOf(i) == -1) {
                    this.arr.push(i);
                    this.arr0.push(i + 1);
                } else {
                    this.arr.splice(this.arr.indexOf(i), 1);
                    this.arr0.splice(this.arr0.indexOf(i + 1), 1);
                }
                //console.log("ball-arr下标-" + this.arr);
                //console.log("ball-arr0号码-" + this.arr0);
            },
            //执行过滤
            filter() {
                console.time("测试:");
                console.time("组合:");
                this.matrix = this.how;
                this.selectball(); //选择投注方式
                this.clickParent();
            },

            //判断投注方式
            selectball() {
                var select = this.how;
                if (this.arr.length < select) {
                    alert("对不起！选号个数要大于等于玩法规定的个数");
                    this.sele = [];
                } else if (select == 7 && this.condition == "") {
                    this.sele = this.select7(api.smallb(this.arr0));
                    this.juzhen(this.sele.length);
                } else {
                    this.condition1();
                }
                console.timeEnd("组合:");
            },
            //@
            juzhen(arr) {
                if (arr != 0 && this.ok == true) {
                    var aa = this.Rotation(this.sele);
                    this.sele = this.Rotation(aa);
                }
            },
            //选七组合
            select7(arr) {
                var len0 = 0;
                var len3 = arr.length;
                if (len3 > 15) {
                    this.sele = [];
                    var len2 = parseInt(len3 / 2);
                    //var a0 = this.combination2(6);
                    return this.z07(arr, len0, len2, len3);
                } else {
                    this.sele = [];
                    return this.sele7(arr, len0, len3);
                }
            },
            //选1-----
            sele1(arr, len1, len2) {
                var arr1 = [];
                for (var a = len1; a < len2; a++) {
                    var x = [];
                    x.push(arr[a]);
                    arr1.push(x);
                }
                return arr1;
            },
            //选2-----
            sele2(arr, len1, len2) {
                var arr1 = [];
                for (var a = len1; a < len2; a++) {
                    for (var b = a + 1; b < len2; b++) {
                        var x = [];
                        x.push(arr[a], arr[b]);
                        arr1.push(x);
                    }
                }
                return arr1;
            },
            //选3-----
            sele3(arr, len1, len2) {
                var arr1 = [];
                for (var a = len1; a < len2; a++) {
                    for (var b = a + 1; b < len2; b++) {
                        for (var c = b + 1; c < len2; c++) {
                            var x = [];
                            x.push(arr[a], arr[b], arr[c]);
                            arr1.push(x);
                        }
                    }
                }
                return arr1;
            },
            //选4-----
            sele4(arr, len1, len2) {
                var arr1 = [];
                for (var a = len1; a < len2; a++) {
                    for (var b = a + 1; b < len2; b++) {
                        for (var c = b + 1; c < len2; c++) {
                            for (var d = c + 1; d < len2; d++) {
                                var x = [];
                                x.push(arr[a], arr[b], arr[c], arr[d]);
                                arr1.push(x);
                            }
                        }
                    }
                }
                return arr1;
            },
            //选5----
            sele5(arr, len1, len2) {
                var arr1 = [];
                for (var a = len1; a < len2; a++) {
                    for (var b = a + 1; b < len2; b++) {
                        for (var c = b + 1; c < len2; c++) {
                            for (var d = c + 1; d < len2; d++) {
                                for (var e = d + 1; e < len2; e++) {
                                    var x = [];
                                    x.push(arr[a], arr[b], arr[c], arr[d], arr[e]);
                                    arr1.push(x);
                                }
                            }
                        }
                    }
                }
                return arr1;
            },
            //选6----
            sele6(arr, len1, len2) {
                var arr1 = [];
                for (var a = len1; a < len2; a++) {
                    for (var b = a + 1; b < len2; b++) {
                        for (var c = b + 1; c < len2; c++) {
                            for (var d = c + 1; d < len2; d++) {
                                for (var e = d + 1; e < len2; e++) {
                                    for (var f = e + 1; f < len2; f++) {
                                        var x = [];
                                        x.push(arr[a], arr[b], arr[c], arr[d], arr[e], arr[f]);
                                        arr1.push(x);
                                    }
                                }
                            }
                        }
                    }
                }
                return arr1;
            },
            //选7----
            sele7(arr, len1, len2) {
                var arr1 = [];
                for (var a = len1; a < len2; a++) {
                    for (var b = a + 1; b < len2; b++) {
                        for (var c = b + 1; c < len2; c++) {
                            for (var d = c + 1; d < len2; d++) {
                                for (var e = d + 1; e < len2; e++) {
                                    for (var f = e + 1; f < len2; f++) {
                                        for (var g = f + 1; g < len2; g++) {
                                            var x = [];
                                            x.push(
                                                arr[a],
                                                arr[b],
                                                arr[c],
                                                arr[d],
                                                arr[e],
                                                arr[f],
                                                arr[g]
                                            );
                                            arr1.push(x);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return arr1;
            },

            z07(arr, len1, len2, len3) {
                var a1 = [];
                a1 = a1.concat(
                    this.sele7(arr, len1, len2).concat(this.sele7(arr, len2, len3))
                );
                a1 = a1.concat(
                    this.merge(this.sele1(arr, len1, len2), this.sele6(arr, len2, len3))
                );
                a1 = a1.concat(
                    this.merge(this.sele2(arr, len1, len2), this.sele5(arr, len2, len3))
                );
                a1 = a1.concat(
                    this.merge(this.sele3(arr, len1, len2), this.sele4(arr, len2, len3))
                );
                a1 = a1.concat(
                    this.merge(this.sele4(arr, len1, len2), this.sele3(arr, len2, len3))
                );
                a1 = a1.concat(
                    this.merge(this.sele5(arr, len1, len2), this.sele2(arr, len2, len3))
                );
                a1 = a1.concat(
                    this.merge(this.sele6(arr, len1, len2), this.sele1(arr, len2, len3))
                );
                return a1;
            },
            //选择
            select(num, arr, len1, len2) {
                var arr0 = [];
                if (num == 1) {
                    arr0 = this.sele1(arr, len1, len2);
                } else if (num == 2) {
                    arr0 = this.sele2(arr, len1, len2);
                } else if (num == 3) {
                    arr0 = this.sele3(arr, len1, len2);
                } else if (num == 4) {
                    arr0 = this.sele4(arr, len1, len2);
                } else if (num == 5) {
                    arr0 = this.sele5(arr, len1, len2);
                } else if (num == 6) {
                    arr0 = this.sele6(arr, len1, len2);
                } else if (num == 7) {
                    arr0 = this.sele7(arr, len1, len2);
                } else if (num == 8) {
                    arr0 = this.sele8(arr, len1, len2);
                } else if (num == 9) {
                    arr0 = this.sele9(arr, len1, len2);
                } else if (num == 10) {
                    arr0 = this.select10(arr, len1, len2);
                }
                return arr0;
            },
            //计算组合2
            combination2(arr) {
                var arr0 = [];
                for (var a = 0; a <= arr; a++) {
                    for (var b = 0; b <= arr; b++) {
                        if (a + b == arr) {
                            var arr1 = [];
                            arr1.push(a, b);
                            arr0.push(arr1);
                        }
                    }
                }
                return arr0;
            },
            //计算组合3
            combination3(arr) {
                var arr0 = [];
                for (var a = 0; a <= arr; a++) {
                    for (var b = 0; b <= arr; b++) {
                        for (var c = 0; c <= arr; c++) {
                            if (a + b + c == arr) {
                                var arr1 = [];
                                arr1.push(a, b, c);
                                arr0.push(arr1);
                            }
                        }
                    }
                }
                return arr0;
            },
            //合并数组
            merge(arr1, arr2) {
                var arr = [];
                for (var a = 0; a < arr1.length; a++) {
                    for (var b = 0; b < arr2.length; b++) {
                        arr.push(arr1[a].concat(arr2[b]));
                    }
                }
                return arr;
            },
            //合并数组2
            merge2(a6, b6, c6) {
                var arr = [];
                for (var a = 0; a < a6.length; a++) {
                    for (var b = 0; b < b6.length; b++) {
                        for (var c = 0; c < c6.length; c++) {
                            if (a6[a].length + b6[b].length + c6[c].length == 6) {
                                arr.push(a6[a].concat(b6[b]).concat(c6[c]));
                            }
                        }
                    }
                }
                return arr;
            },
            //胆码组--条件进行过滤
            condition1() {
                //console.time("forEach:")
                //console.timeEnd("forEach:");
                console.time("过滤:");
                //var ax = [];
                var abc = [];
                var max = [];
                var arr3 = [];
                var j1 = []; //胆码组外选号

                for (var c = 0; c < this.condition.length; c++) {
                    var dm = this.condition[c].dm;
                    var gs = this.condition[c].gs;
                    var obj = {
                        dm: dm,
                        gs: gs,
                    };
                    j1 = j1.concat(this.condition[c].dm);
                    arr3 = arr3.concat(obj);
                }
                //胆码组外号码
                var dm3 = this.getArrDifference(j1, this.arr0);
                if (dm3 != "" && dm3 != null) {
                    var gs3 = [];
                    for (var j2 = 0; j2 < dm3.length; j2++) {
                        gs3 = gs3.concat(j2);
                    }
                    var obj3 = [
                        {
                            dm: dm3,
                            gs: gs3,
                        },
                    ];
                    arr3 = arr3.concat(obj3);
                }

                for (var j = 0; j < arr3.length; j++) {
                    for (var r = j; r < arr3.length; r++) {
                        if (arr3[j].dm != "" && arr3[j].dm != null) {
                            if (arr3[j].dm.length > arr3[r].dm.length) {
                                //如果arr[j]大就把此时的值赋值给最大值变量max
                                max = arr3[r];
                                arr3[r] = arr3[j];
                                arr3[j] = max;
                            }
                        }
                    }
                }

                var ce = [];
                var arr4 = [];
                for (var d = 0; d < arr3.length; d++) {
                    var dm2 = [];
                    var gs2 = [];
                    if (arr3[d].dm != "" && arr3[d].dm != null) {
                        for (var e = 0; e < arr3[d].dm.length; e++) {
                            if (ce.indexOf(arr3[d].dm[e]) == -1) {
                                ce = ce.concat(arr3[d].dm[e]);
                                dm2 = dm2.concat(arr3[d].dm[e]);
                            }
                        }
                    }
                    if (arr3[d].gs != "" && arr3[d].gs != null) {
                        for (var f = 0; f < arr3[d].gs.length; f++) {
                            if (dm2.length + 1 > arr3[d].gs[f]) {
                                gs2 = gs2.concat(arr3[d].gs[f]);
                            }
                        }
                    }

                    var obj2 = {
                        dm: dm2,
                        gs: gs2,
                    };
                    arr4.push(obj2);
                }

                for (var g = 0; g < arr4.length; g++) {
                    abc.push(arr4[g].gs);
                    //ax.push(arr4[g].dm)
                }

                var ab = this.getArrayByArrays(abc);
                var ac = this.perk(ab);
                //var ad = [];
                var ae = [];
                for (var a = 0; a < ac.length; a++) {
                    var arr = [];
                    for (var b = 0; b < ac[a].length; b++) {
                        var len1 = 0;
                        var len2 = arr4[b].dm.length;
                        var arr1 = arr4[b].dm;
                        if (ac[a][b] != 0) {
                            arr.push(this.select(ac[a][b], arr1, len1, len2));
                        }
                    }
                    ae = ae.concat(this.doExchange(arr));
                }
                //矩阵
                if (ae.length == 0) {
                    alert("对不起！没有符合条件的号码！");
                    this.sele = [];
                } else {
                    if (this.ok == true) {
                        var aa = this.Rotation(ae);
                        this.sele = this.Rotation(aa);
                    } else {
                        this.sele = ae;
                    }
                }
                console.timeEnd("过滤:");
            },
            //递归矩阵缩水
            getArrDifference(arr1, arr2) {
                return arr1.concat(arr2).filter(function (v, i, arr) {
                    return arr.indexOf(v) === arr.lastIndexOf(v);
                });
            },
            //递归矩阵缩水
            doExchange(doubleArrays) {
                var len = doubleArrays.length;
                if (len >= 2) {
                    var len1 = doubleArrays[0].length;
                    var len2 = doubleArrays[1].length;
                    var newlen = len1 * len2;
                    var temp = new Array(newlen);
                    var index = 0;
                    for (var i = 0; i < len1; i++) {
                        for (var j = 0; j < len2; j++) {
                            temp[index] = api.smallb(
                                (doubleArrays[0][i] + "," + doubleArrays[1][j])
                                    .split(",")
                                    .map(Number)
                            );
                            index++;
                        }
                    }
                    var newArray = new Array(len - 1);
                    for (var i1 = 2; i1 < len; i1++) {
                        newArray[i1 - 1] = doubleArrays[i1];
                    }
                    newArray[0] = temp;
                    return this.doExchange(newArray);
                } else {
                    return doubleArrays[0];
                }
            },
            //对条件出号个数进行组合
            getArrayByArrays(arrays) {
                var arr = [""];
                for (var i = 0; i < arrays.length; i++) {
                    arr = this.getValuesByArray(arr, arrays[i]);
                }
                return arr;
            },
            //对条件出号个数进行组合
            getValuesByArray(arr1, arr2) {
                var arr = [];
                for (var i = 0; i < arr1.length; i++) {
                    var v1 = arr1[i];
                    for (var j = 0; j < arr2.length; j++) {
                        var v2 = [];
                        v2 = arr2[j];
                        var value = [];
                        //console.log(value.concat(v1).length)
                        arr.push(value.concat(v1.concat(v2)));

                        //console.log(typeof(value2))
                        //value ,v2,v1
                    }
                }
                return arr;
            },
            //对胆码组符合条件的进行过滤
            perk(ab) {
                var arr = [];
                for (var a = 0; a < ab.length; a++) {
                    var sum = 0;
                    for (var b = 0; b < ab[a].length; b++) {
                        sum += parseInt(ab[a][b]);
                    }
                    if (sum == this.selec()) {
                        arr.push(ab[a]);
                    }
                }
                return arr;
            },
            //弹窗/胆码组
            Courage() {
                var select = this.how;
                this.arr3 = [1000]; //点击胆码组进入弹窗不需要修改按钮显示红色
                if (select !== "0") {
                    this.wind = true;
                } else {
                    alert("请选择投注方式！");
                }
            },
            //弹窗/@
            Rotationmatrix() {
                var select = [];
                select.push(parseInt(this.how));
                if (select != "0") {
                    this.matrix = select;
                    this.windmatrix = true;
                    this.ok = true;
                } else {
                    alert("请选择投注方式！");
                }
            },
            //@
            Rotation(ae) {
                var arr = [];
                console.time("过滤0");
                if (ae.length < 10000) {
                    arr = arr.concat(this.Rotation2(ae));
                } else {
                    for (var a = 0; a < ae.length; a += 10000) {
                        if (ae.length - a < 10000) {
                            arr = arr.concat(this.Rotation2(ae.slice(a, ae.length - 1)));
                            break;
                        } else {
                            arr = arr.concat(this.Rotation2(ae.slice(a, a + 10000)));
                        }
                    }
                }
                console.timeEnd("过滤0");
                return arr;
            },

            Rotation2(ae2) {
                for (var h = 0; h < ae2.length - 1; h++) {
                    if (this.getArrDifference(ae2[h], ae2[h + 1]).length <= 2) {
                        ae2.splice(h + 1, 1);
                        if (h != 0) {
                            h = h - 1;
                        }
                    }
                }
                // console.log(ae);
                return ae2;
            },
            //当前玩法
            selec() {
                return this.how;
            },
            //子组件回传@玩法数据
            matrix1(arr) {
                this.matrix = [];
                this.matrix = arr;
            },
            //接收子组件胆码组弹窗传过来的数据
            getMsgFormSon(arr) {
                this.condition = [];
                // var array=[]//对子组件传过来的胆码组进行排序去重\
                this.arr = [];
                this.arr0 = [];
                for (var i = 0; i < arr.length; i++) {
                    var dmz = arr[i].dmz;
                    var dm = arr[i].dm;
                    var gs = arr[i].gs;
                    var hmxb = arr[i].hmxb;

                    for (var y = 0; y < arr[i].dm.length; y++) {
                        if (this.arr0.indexOf(arr[i].dm[y]) == -1) {
                            this.arr0.push(arr[i].dm[y]);
                            this.arr.push(arr[i].dm[y] - 1);
                        }
                    }
                    //console.log("getMsg-arr-下标" + this.arr);
                    //console.log("getMsg-arr0-号码" + this.arr0);

                    var obj = {
                        dmz: dmz,
                        dm: dm,
                        gs: gs,
                        hmxb: hmxb,
                    };
                    this.condition.push(obj);
                    for (var j = 0; j < this.condition.length; j++) {
                        //删除条件后对新的条件进行序号更新
                        if (this.condition[j].dmz !== j) this.condition[j].dmz = j;
                    }
                }
            },
            //条件修改
            rev(i) {
                this.wind = true;
                this.arr3 = [];
                this.arr3.push(i);
            },
            //胆码组删除条件数据
            del(i) {
                //更新号码显示
                // for (var y = 0; y < this.arr0.length; y++) {
                //     for (var j1 = 0; j1 < this.condition[i].dm.length; j1++) {
                //         if (this.arr0[y] == this.condition[i].dm[j1]) {
                //             this.arr0.splice(y, 1);
                //         }
                //         if (this.arr[y] == this.condition[i].dm[j1] - 1) {
                //             this.arr.splice(y, 1);
                //         }
                //     }
                // }
                //删除条件数据
                this.condition.splice(i, 1);
                this.getMsgFormSon(this.condition);
                for (var j = 0; j < this.condition.length; j++) {
                    //删除条件后对新的条件进行序号更新
                    if (this.condition[j].dmz !== j) this.condition[j].dmz = j;
                }
            },
            //@删除
            del2() {
                this.matrix = [];
                this.ok = false;
            },
            //调用子组件方法传递组合数据 /执行过滤
            clickParent() {
                this.$refs.ssqlist.parentHandleclick(this.sele, this.lan);
                console.timeEnd("测试:");
            },
            //保存号码
            savetxt(arr) {
                if (arr == "" || arr == null) {
                    alert("没有需要保存的号码！");
                } else {
                    var filename = +new Date() + ".txt";
                    var text = [];
                    for (var i = 0; i < arr.length; i++) {
                        text.push(arr[i] + "/");
                    }
                    //内容  换行用 \n
                    var element = document.createElement("a");
                    element.setAttribute(
                        "href",
                        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
                    );
                    element.setAttribute("download", "haoma" + filename);
                    element.style.display = "none";
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }
            },
            //重置按钮
            reset() {
                this.condition = [];
                this.matrix = [];
                this.arr = [];
                this.arr0 = [];
            },
            //保存条件
            save() {
                var arr = [];
                if (this.condition != null || this.condition != "") {
                    for (var a = 0; a < this.condition.length; a++) {
                        var obj = [
                            "dmz",
                            this.condition[a].dmz + "/",
                            "dm",
                            this.condition[a].dm + "/",
                            "gs",
                            this.condition[a].gs + "/qlc",
                        ];
                        arr.push(obj);
                    }
                }
                if (this.matrix.length > 0 && this.ok == true) {
                    var obj0 = ["jz", this.selec() + "/qlc"];
                    arr.push(obj0);
                }
                if (arr == "" || arr == null) {
                    alert("对不起,没有需要保存的条件！");
                } else {
                    var filename = +new Date() + ".txt";
                    var text = [];
                    for (var i = 0; i < arr.length; i++) {
                        text.push(arr[i]);
                    }
                    //内容  换行用 \n
                    var element = document.createElement("a");
                    element.setAttribute(
                        "href",
                        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
                    );
                    element.setAttribute("download", "tiaojian" + filename);
                    element.style.display = "none";
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }
            },
            //导入条件 监听
            doUpload() {
                const fileNode = document.getElementById("filePath");
                fileNode.addEventListener("change", this.test);
                //fileNode.addEventListener("change", this.changepic );
                fileNode.click();
            },
            //导入条件 读取
            test() {
                var files = this.$refs.fileinput.files;
                if (files.length) {
                    var name = files[0].name;
                    var ss = name.substr(name.lastIndexOf(".")).toUpperCase(); //得到的是后缀名,且转换为大写
                    if (ss == ".TXT") {
                        var reader = new FileReader(); //new一个FileReader实例
                        if (typeof FileReader == "undefined") {
                            alert("你的浏览器暂不支持该功能");
                            return;
                        }
                        reader.readAsText(files[0]);
                        let self = this;
                        reader.onload = (ev) => {
                            if (
                                ev.target.result.substr(
                                    ev.target.result.length - 4,
                                    ev.target.result.length
                                ) == "/qlc"
                            ) {
                                var relArr = ev.target.result
                                    .substr(0, ev.target.result.length - 4)
                                    .split("/qlc,");
                                var zdm = [];
                                var zdmxb = [];
                                for (var a = 0; a < relArr.length; a++) {
                                    var dmz = [];
                                    var dm = [];
                                    var gs = [];
                                    var jz = [];
                                    var dmxb = [];
                                    var relArr2 = relArr[a].split("/,");
                                    // console.log(relArr[a].split(',')[0])
                                    if (relArr[a].split(",")[0] == "dmz") {
                                        for (var b = 0; b < relArr2.length; b++) {
                                            var relArr3 = relArr2[b].split(",");
                                            for (var c = 0; c < relArr3.length - 1; c++) {
                                                if (relArr3[0] == "dmz") {
                                                    dmz.push(relArr3[c + 1]);
                                                }
                                                if (relArr3[0] == "dm") {
                                                    dm.push(relArr3[c + 1]);
                                                    dmxb.push(relArr3[c + 1] - 1);
                                                }
                                                if (relArr3[0] == "gs") {
                                                    gs.push(relArr3[c + 1]);
                                                }
                                            }
                                        }

                                        if (dmz != "" || dmz != null) {
                                            var obj = {
                                                dmz: dmz.map(Number),
                                                dm: dm.map(Number),
                                                gs: gs.map(Number),
                                            };
                                            zdm = zdm.concat(dm.map(Number));
                                            zdmxb = zdmxb.concat(dmxb.map(Number));
                                            self.condition.push(obj);
                                        }
                                    } else if (relArr[a].split(",")[0] == "jz") {
                                        for (var d = 0; d < relArr2.length; d++) {
                                            var relArr4 = relArr2[d].split(",");
                                            // console.log(relArr3)
                                            for (var e = 0; e < relArr4.length - 1; e++) {
                                                if (relArr4[0] == "jz") {
                                                    jz.push(relArr4[e + 1]);
                                                }
                                            }
                                        }

                                        if (jz != "" || jz != null) {
                                            self.matrix = jz.map(Number);
                                            self.ok = true;
                                            this.$refs.col.value = jz.map(Number);
                                        }
                                    } else {
                                        alert("对不起！文件内容不正确！");
                                    }
                                }
                                self.arr = zdmxb;
                            } else {
                                alert("此条件不是七乐彩玩法");
                            }

                            // self.arr0=zdm总胆码组数据未使用
                        };
                    } else {
                        alert("文件格式不支持!");
                    }
                }
                const fileNode = document.getElementById("filePath");
                fileNode.removeEventListener("change", this.test);
            },
            //导入号码
            doUpload2() {
                const fileNode = document.getElementById("filePath2");
                fileNode.addEventListener("change", this.test2);
                //fileNode.addEventListener("change", this.changepic );
                fileNode.click();
            },
            //导入号码 读取
            test2() {
                var files = this.$refs.fileinput2.files;
                // var regp = new RegExp(".*,\".*,.*\"$")
                // console.log(files[0])
                if (files.length) {
                    var name = files[0].name;
                    var ss = name.substr(name.lastIndexOf(".")).toUpperCase(); //得到的是后缀名,且转换为大写
                    if (ss == ".TXT") {
                        var reader = new FileReader(); //new一个FileReader实例
                        if (typeof FileReader == "undefined") {
                            alert("你的浏览器暂不支持该功能");
                            return;
                        }
                        reader.readAsText(files[0]);
                        let self = this;
                        reader.onload = (ev) => {
                            if (ev.target.result[ev.target.result.length - 1] == "/") {
                                var relArr = ev.target.result
                                    .substr(0, ev.target.result.length - 1)
                                    .split("/,");
                                // var relArr2=relArr[relArr.length-1].replace('/','')
                                self.sele = relArr;
                                self.clickParent();
                            } else {
                                alert("对不起！文件内容不正确！");
                            }
                        };
                    } else {
                        alert("文件格式不支持!");
                    }
                }
                const fileNode = document.getElementById("filePath");
                fileNode.removeEventListener("change", this.test);
            },
            //尾数选择函数1
            ball3(i) {
                if (i == 0) {
                    this.tail(this.ball3_1(9));
                } else if (i == 1) {
                    this.tail(this.ball3_1(0));
                } else if (i == 2) {
                    this.tail(this.ball3_1(1));
                } else if (i == 3) {
                    this.tail(this.ball3_1(2));
                } else if (i == 4) {
                    this.tail(this.ball3_1(3));
                } else if (i == 5) {
                    this.tail(this.ball3_1(4));
                } else if (i == 6) {
                    this.tail(this.ball3_1(5));
                } else if (i == 7) {
                    this.tail(this.ball3_1(6));
                } else if (i == 8) {
                    this.tail(this.ball3_1(7));
                } else if (i == 9) {
                    this.tail(this.ball3_1(8));
                } else if (i == 10) {
                    var aa = [];
                    for (var v1 = 0; v1 < 30; v1++) {
                        if (v1 % 2 == 0) {
                            aa.push(v1);
                        }
                    }
                    this.tail(aa);
                } else if (i == 11) {
                    var aa1 = [];
                    for (var v2 = 0; v2 < 30; v2++) {
                        if (v2 % 2 == 1) {
                            aa1.push(v2);
                        }
                    }
                    this.tail(aa1);
                } else if (i == 12) {
                    var aa2 = [];
                    for (var v3 = 0; v3 < 30; v3++) {
                        //大尾
                        if (v3 % 10 > 3 && v3 % 10 < 9) {
                            aa2.push(v3);
                        }
                    }
                    this.tail(aa2);
                } else if (i == 13) {
                    var aa3 = []; //小尾
                    for (var v4 = 0; v4 < 30; v4++) {
                        if (v4 % 10 < 4 || v4 % 10 == 9) {
                            aa3.push(v4);
                        }
                    }
                    this.tail(aa3);
                } else if (i == 14) {
                    var aa4_1 = api.isPrime(30); //质数
                    this.tail(this.ball3_2(aa4_1));
                } else if (i == 15) {
                    var aa5 = []; //合数
                    var aa5_1 = api.isPrime(30);
                    for (var v6 = 1; v6 <= 30; v6++) {
                        if (aa5_1.indexOf(v6) == -1) {
                            aa5.push(v6 - 1);
                        }
                    }
                    this.tail(aa5);
                } else if (i == 16) {
                    //147尾
                    var aa6_1 = api.ysq(30); //147
                    this.tail(this.ball3_2(aa6_1));
                } else if (i == 17) {
                    //258
                    var aa7_1 = api.ewb(30);
                    this.tail(this.ball3_2(aa7_1));
                } else if (i == 18) {
                    //0369
                    var aa8_1 = api.slj(30);
                    this.tail(this.ball3_2(aa8_1));
                } else if (i == 19) {
                    for (var j = 0; j < 30; j++) {
                        if (this.arr.indexOf(j) == -1) {
                            this.arr.push(j);
                            this.arr0.push(j + 1);
                        }
                    }
                } else if (i == 20) {
                    for (var j1 = 1; j1 <= 30; j1++) {
                        this.arr.splice(this.arr.indexOf(j1), 1);
                        this.arr0.splice(this.arr0.indexOf(j1 + 1), 1);
                    }
                }
            },
            //尾数选择函数2
            ball3_1(i) {
                var bal = [];
                for (var v = 0; v < 30; v++) {
                    if (v % 10 == i) {
                        bal.push(v);
                    }
                }
                return bal;
            },
            //尾数选择函数3
            ball3_2(aa4_1) {
                var aa4 = [];
                for (var v5 = 1; v5 <= 30; v5++) {
                    if (aa4_1.indexOf(v5) != -1) {
                        aa4.push(v5 - 1);
                    }
                }
                return aa4;
            },
            //尾数选择函数4
            tail(array) {
                var tail_1 = api.same(array, this.arr);
                if (tail_1.length != array.length) {
                    for (var j = 0; j < array.length; j++) {
                        if (this.arr.indexOf(array[j]) == -1) {
                            this.arr.push(array[j]);
                            this.arr0.push(array[j] + 1);
                        }
                    }
                } else {
                    for (var j1 = 0; j1 < array.length; j1++) {
                        this.arr.splice(this.arr.indexOf(array[j1]), 1);
                        this.arr0.splice(this.arr0.indexOf(array[j1] + 1), 1);
                    }
                }
            },
             // 蓝球处理弹窗
            // windblue1() {
            //     if (this.sele.length != 0) {
            //         this.windblue = true;
            //     } else {
            //         alert("没有需要处理的蓝球");
            //     }
            // },

            mon(arr) {//接收list子组件回传的记录数
                this.money = arr
            },

            show(relArr) {
                console.log(relArr);
                console.log("测试一下");
                //var relArr = this.result.split('//,')
                for (var a = 0; a < relArr.length; a++) {
                    var relArr2 = relArr[a].split("/,");
                    var dmz = [];
                    var dm = [];
                    var gs = [];
                    for (var b = 0; b < relArr2.length; b++) {
                        var relArr3 = relArr2[b].split(",");
                        // console.log(relArr3)
                        for (var c = 0; c < relArr3.length - 1; c++) {
                            if (relArr3[0] == "dmz") {
                                dmz.push(relArr3[c + 1]);
                            }
                            if (relArr3[0] == "dm") {
                                dm.push(relArr3[c + 1]);
                            }
                            if (relArr3[0] == "gs") {
                                gs.push(relArr3[c + 1]);
                            }
                        }
                    }
                    if (dmz != "" || dmz != null) {
                        var obj = {
                            dmz: dmz,
                            dm: dm,
                            gs: gs,
                        };
                        //arr.push(obj)
                        console.log("2");
                        console.log(obj);

                        this.condition = obj;
                    }
                }
            },
        },

        watch: {
            arr0() {
                console.log(this.arr);
            },
            arr() {
                console.log(this.arr);
            },
            condition() {
                console.log("条件内容改变了");
                console.log("3");
                console.log(this.condition);
            },
        },

        props: {},
    };
</script>

<style scoped>
    /* @import './swiper.css';  */
    /* * {
        border: 1px solid red;
      } */

    .tem {
        width: 100%;
        border: 1px solid rgb(214, 213, 213);
    }

    .lef {
        float: left;
        width: 40%;
        height: 500px;
        border: 1px solid rgb(214, 213, 213);
    }

    .lef-title {
        width: 100%;
        height: 50px;
        font-size: 16px;
        line-height: 47px;
        border-radius: 10px;
        color: rgb(116, 116, 116);
        background-color: rgb(240, 238, 238);
    }

    .number {
        width: 365px;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 10px;
        border: 1px solid rgb(214, 213, 213);
    }

    .condition {
        width: 100%;
    }

    .condition-1 {
        width: 50%;
        margin: 0 auto;
        height: 50px;
        margin-top: 20px;
        line-height: 47px;
        font-size: 20px;
        background-color: rgb(200, 21, 216);
        border-radius: 15px;
        color: #fff;
    }

    .condition-2 {
        width: 100%;
        margin-top: 30px;
        border: 1px solid rgb(213, 214, 213);
    }

    .condition-2 ul li {
        float: left;
        width: 80px;
        height: 30px;
        line-height: 27px;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 20px;
        border-radius: 5px;
        background-color: rgb(223, 221, 221);
        border: 1px solid rgb(186, 187, 186);
        color: rgb(119, 114, 114);
    }

    .con {
        float: left;
        width: 20%;
        height: 500px;
        line-height: 48px;
        border: 1px solid rgb(238, 236, 236);
    }

    .con-title {
        width: 100%;
        height: 50px;
        font-size: 16px;
        line-height: 47px;
        border-radius: 10px;
        color: rgb(116, 116, 116);
        background-color: rgb(240, 238, 238);
    }

    .con-1 {
        width: 100%;
        height: 350px;
        overflow: auto;
        border: 1px solid rgb(186, 187, 186);
    }

    .con-1 ul li {
        border: 1px solid rgb(216, 218, 216);
    }

    .con-1a {
        line-height: 15px;
        border: 1px solid rgb(212, 210, 210);
    }

    .con-1b {
        line-height: 15px;
    }

    .con-1aa {
        float: left;
        width: 40%;
        font-weight: bold;
    }

    .con-1ab {
        float: right;
        width: 40%;
    }

    .con-1ac {
        float: left;
        width: 100%;
    }

    .con-2 ul li {
        float: left;
        width: 100px;
        height: 30px;
        line-height: 27px;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 7%;
        border-radius: 5px;
        background-color: rgb(223, 221, 221);
        border: 1px solid rgb(186, 187, 186);
        color: rgb(119, 114, 114);
    }

    .con-2 ul .zhixing {
        background: rgb(219, 4, 4);
        color: #fff;
        font-weight: bold;
    }

    .rig {
        float: left;
        width: 39%;
        height: 500px;
        border: 1px solid rgb(214, 213, 213);
    }

    .rig-title {
        width: 100%;
        height: 50px;
        font-size: 16px;
        line-height: 47px;
        border-radius: 10px;
        color: rgb(116, 116, 116);
        background-color: rgb(240, 238, 238);
    }

    .rig-1 {
        width: 100%;
        height: 350px;
        border: 1px solid rgb(186, 187, 186);
    }

    .rig-2 {
        width: 100%;
        margin-top: 10px;
    }

    .rig-21 {
        float: left;
        width: 48%;
    }

    .rig-22 {
        float: left;
        width: 48%;
    }

   

    .rig-3 {
        width: 58%;
        float: right;

        text-align: left;
    }

    .rig-32 {
        color: red;
        font-weight: bold;
    }

    .ball {
        float: left;
        margin-left: 6px;
        margin-top: 6px;
        line-height: 26px;
        width: 28px;
        height: 28px;
        font-size: 15px;
        border-radius: 15px;
        color: #fff;
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(219, 133, 3);
    }

    .button {
        width: 50px;
        border: 1px solid rgb(167, 166, 166);
    }

    .active {
        color: rgb(124, 122, 122);
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(212, 211, 211);
    }

    .con-list {
        overflow: auto;
    }

    .clear {
        clear: both;
    }
</style>